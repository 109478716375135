export default {
  siteTitle: 'Get bonus',
  blogerName: 'ZEXTER',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/zexterpkm'
    },
    {
      name: 'telegram',
      url: 'https://t.me/ZexterCasinos'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/zexterpkm/'
    }
  ],
  projects: [
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c8c538609',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c80e95b5c',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c24edc561',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/cdb845427',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/ce20c9d72',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Regístrate con mi código promocional <strong>ZEXTER</strong> y obtén 50 giros gratis en los siguientes juegos',
  yourPromocode: 'Tu código promocional',
  gameBonus: 'Bono en el juego',
  promocode: 'ZEXTER',
  casinoBonus1: '<strong>100% + 500FS</strong><div>en el primer depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado en el portapapeles',
  theme: 'dark'
};
